// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-page-jsx": () => import("./../../../src/templates/AboutPage.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-blog-page-jsx": () => import("./../../../src/templates/BlogPage.jsx" /* webpackChunkName: "component---src-templates-blog-page-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-index-page-jsx": () => import("./../../../src/templates/IndexPage.jsx" /* webpackChunkName: "component---src-templates-index-page-jsx" */)
}

